body{
  background-image: url(../Images/desktop.png);
  background-size: cover;
}
.Container{
  width: 100%;
  height: 100vh;
}
.Weather-Container{
  width: 50%;
  height: 100vh;
  /* background-color: rgba(179, 122, 179, 0.584); */
  float: right;
  padding: 8%;
}
.fullWidth{
  width: 100%;
}
.social-icons{
  padding: 1%;
}

@media only screen and (max-width: 800px) {
  body {
    background-image: url(../Images/mobile.png);
    background-size: cover;
  }
  .Container{
    height: auto;
  }
  .Weather-Container{
    width: 100%;
    height: 100vh;
    float: none;
    padding: 6%;
  }
  .social-icons{
    background-color: black;
  }
  .social-icons a{
    padding: 3%;
  }
}
@media only screen and (max-width: 400px) {
  .Weather-Container{
    width: 100%;
    height: 700px;
    background-color: none;
    float: none;
    padding: 6%;
  }
  .social-icons{
    background-color: transparent;
  }
  .social-icons a{
    padding: 3%;
  }

}
